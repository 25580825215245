export default function (string: string) {
  const checkBase64 = string.includes('data:')

  let url

  try {
    url = new URL(string)
  }
  // eslint-disable-next-line unused-imports/no-unused-vars
  catch (_) {
    return checkBase64
  }

  return url.protocol === 'http:' || url.protocol === 'https:' || checkBase64
}
